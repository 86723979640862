.work-permits-container {
  padding: 1rem;
  background-color: #f5f5f5;
}

.page-title {
  color: #363062; /* eleghoBlue */
  font-size: 1.25rem; /* text-lg */
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Search styles */
.search-container {
  margin-bottom: 1.5rem;
}

.search-bar {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.search-input {
  flex: 1;
  padding: 0.625rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.875rem;
  outline: none;
  transition: border-color 0.2s;
}

.search-input:focus {
  border-color: #363062; /* eleghoBlue */
}

.search-filter {
  padding: 0.625rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.875rem;
  background-color: white;
  color: #363062; /* eleghoBlue */
  outline: none;
  min-width: 120px;
}

/* Status filter buttons */
.status-filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.status-btn {
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
  border: 1.5px solid;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 85px;
  text-align: center;
}

.status-btn:hover {
  opacity: 0.9;
}

.status-btn.active {
  font-weight: 600;
}

.status-btn.clear-btn {
  border-color: #666;
  color: #666;
}

.status-btn.clear-btn:hover {
  background-color: #f1f1f1;
}

.filter-info {
  min-height: 1.5rem;
}

.no-results {
  color: #f44336; /* red */
  font-size: 0.875rem;
  margin: 0.25rem 0;
}

.results-count {
  color: #363062; /* eleghoBlue */
  font-size: 0.875rem;
  margin: 0.25rem 0;
}

.permits-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.permit-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.permit-status {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  padding: 0.25rem 0.75rem;
  border-bottom-left-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
}

.permit-header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.permit-id {
  font-size: 1.125rem;
  color: #363062; /* eleghoBlue */
  margin: 0;
}

.permit-type {
  font-size: 0.875rem;
  color: #666;
  margin: 0.25rem 0 0;
  text-transform: capitalize;
}

.permit-info {
  padding: 1rem;
}

.info-row {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: flex-start;
}

.info-label {
  font-weight: 500;
  color: #363062; /* eleghoBlue */
  width: 30%;
  font-size: 0.875rem;
}

.info-value {
  flex: 1;
  font-size: 0.875rem;
  color: #333;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.permit-footer {
  padding: 0.75rem 1rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.view-details-btn {
  background-color: #363062; /* eleghoBlue */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.view-details-btn:hover {
  background-color: #2a254b; /* Darker eleghoBlue */
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(54, 48, 98, 0.1); /* eleghoBlue with opacity */
  border-radius: 50%;
  border-top-color: #8fcd00; /* eleghoGreen */
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* For tablets and above */
@media (min-width: 640px) {
  .permits-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .permits-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
