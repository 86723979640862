/**
 * Company type specific styles
 * These styles will be applied based on the company type (condo or hoa)
 */

/* Default styles (for condo) */
body {
  --company-primary-color: #363062;
  --company-secondary-color: #4d4c7d;
  --company-accent-color: #8fcd00;
}

/* HOA specific styles */
body[data-company-type="hoa"] {
  --company-primary-color: #2d3e50;
  --company-secondary-color: #34495e;
  --company-accent-color: #27ae60;
}

/* Apply custom colors to elements */
.company-primary {
  color: var(--company-primary-color);
}

.company-secondary {
  color: var(--company-secondary-color);
}

.company-accent {
  color: var(--company-accent-color);
}

.company-primary-bg {
  background-color: var(--company-primary-color);
}

.company-secondary-bg {
  background-color: var(--company-secondary-color);
}

.company-accent-bg {
  background-color: var(--company-accent-color);
}

/* Company type specific text replacements */
body[data-company-type="hoa"] .label-unit:before {
  content: "Resident";
}

body[data-company-type="condo"] .label-unit:before {
  content: "Unit";
}

body[data-company-type="hoa"] .label-owner:before {
  content: "Lot Owner";
}

body[data-company-type="condo"] .label-owner:before {
  content: "Unit Owner";
}

/* Add more styling as needed */
