@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-gantt/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Mobile view styles */
.mobile-view .bg-eleghoBlue {
  @apply !hidden;
}

.mobile-view nav.bg-eleghoBlue {
  @apply !hidden;
}

.mobile-view nav {
  @apply !hidden;
}

.mobile-view .nav {
  @apply !hidden;
}

/* Target main navbar specifically */
.mobile-view .main-navbar {
  @apply !hidden;
}

/* Target UserNavBar specifically */
.mobile-view div[class*="bg-gray-200"][class*="rounded-t-xl"] {
  @apply !hidden;
}

/* Target Navbar specifically with more selectors */
.mobile-view .bg-eleghoBlue.shadow-lg {
  @apply !hidden;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.small-form {
  @apply min-w-[350px] min-h-[450px] bg-white rounded-lg shadow-md p-2;
}

.close-button {
  @apply absolute top-0 right-0 p-2 underline text-xs;
}

.header {
  @apply text-blue-900 font-medium uppercase text-lg;
}

.blue-button {
  @apply bg-blue-900 px-2 lg:py-1 py-2 text-xs rounded-lg min-w-12 text-white disabled:bg-opacity-50;
}

.green-button {
  @apply bg-green-500 px-2 lg:py-1 py-2 text-xs rounded-lg min-w-12 text-white disabled:bg-opacity-50;
}

.bubble {
  @apply bg-white text-gray-600 rounded-lg;
}

.active-pill {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.inactive-pill {
  @apply bg-gray-200 text-gray-600 px-2 py-1 rounded-full border text-xs border-blue-900 min-w-12 w-fit text-center cursor-pointer;
}

.published {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.unpublished {
  @apply bg-gray-200 text-gray-600 px-2 py-1 rounded-full border text-xs border-blue-900 min-w-12 w-fit text-center cursor-pointer;
}

.basic-input {
  @apply border border-gray-300 p-2 rounded-lg w-full;
}

.activeLinkStyle {
  @apply bg-white min-w-12 text-gray-900 shadow-lg rounded-lg font-medium uppercase p-2 cursor-pointer;
}

.inactiveLinkStyle {
  @apply text-gray-500 min-w-12 font-medium hover:bg-white cursor-pointer p-2 rounded-lg uppercase hover:shadow-lg;
}

.links-container {
  @apply flex items-center justify-start gap-4 bg-gray-200 rounded-lg p-1 lg:w-fit w-full;
}

.pending {
  @apply bg-yellow-500 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.completed {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.rejected {
  @apply bg-red-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.inactive {
  @apply bg-gray-200 text-gray-600 px-2 py-1 rounded-full border text-xs border-blue-900 min-w-12 w-fit text-center cursor-pointer;
}

.activeStatus {
  @apply bg-blue-900 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.processing {
  @apply bg-yellow-300 text-gray-600 px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.card {
  @apply bg-white shadow-sm rounded-md p-2;
}

.approved {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.admitted {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.returned {
  @apply bg-red-300 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.resolved {
  @apply bg-green-700 text-white px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.dispatched {
  @apply bg-green-300 text-gray-600 px-2 py-1 rounded-full text-xs w-fit min-w-12 text-center cursor-pointer;
}

.comment {
  @apply bg-gray-200 text-gray-600 rounded-md shadow-sm p-2;
}

.page {
  @apply bg-gray-100 shadow-md rounded-lg w-full min-h-screen p-4 pt-8 text-xs relative;
}

.category-container {
  @apply flex items-center justify-between text-xs bg-white overflow-hidden text-center divide-x divide-gray-300;
}

.active-category {
  @apply cursor-pointer bg-eleghoGreen text-eleghoBlue px-1 py-4 grow font-bold text-center;
}

.inactive-category {
  @apply cursor-pointer bg-white text-gray-400  px-1 py-4 font-bold grow text-center;
}

.small-form-button {
  @apply bg-blue-900 text-white px-2 py-2 rounded-lg text-xs min-w-12 w-full disabled:bg-opacity-50;
}

.dot {
  @apply w-2 h-2 bg-gray-400 rounded-full;
}

.react-calendar {
  font-family: "Poppins", sans-serif !important;
  border: none !important;
  @apply rounded-lg text-eleghoBlue !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply bg-eleghoGreen !important;
}

.react-calendar__tile--now {
  @apply bg-white text-gray-600 underline font-bold !important;
}

.section-box {
  @apply bg-white shadow-md rounded-lg p-4;
}

.section-header {
  @apply text-lg font-semibold text-eleghoBlue border-b border-gray-600 mb-4;
}

.tab-page {
  @apply text-left text-gray-600 max-w-7xl mx-auto p-4;
}

.tab-page-header {
  @apply text-lg font-semibold text-eleghoBlue border-gray-600 mb-4;
}

.page-header {
  @apply text-lg font-medium text-gray-900 text-left;
}

.blue-gradient {
  @apply bg-gradient-to-r from-eleghoBlue to-eleghoBlue/80 px-6 py-4 flex items-center justify-between;
}

.title-header {
  @apply text-lg font-medium text-eleghoBlue;
}
